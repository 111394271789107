import React, { useEffect } from 'react'
import { Formtitle, Layout } from '../../components'
import { ApprenticeshipFormType } from '../../utils/sharedTypes'
import { Button, Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { situation_before_contract, situation_before_contract_pro } from '../../utils/constants'
import { useNavigate } from 'react-router-dom'
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md'
import { isApprenticeshipStepValid } from '../../utils/misc'
import { client } from '../../utils/client'
import Bugsnag from '@bugsnag/js'

type SituationPropsType = {
  form: ApprenticeshipFormType
  setForm: React.Dispatch<React.SetStateAction<ApprenticeshipFormType>>
}

const FixedMenuItem: React.FC<React.ComponentProps<typeof MenuItem>> = (props) => (
  <MenuItem style={{ whiteSpace: 'normal' }} {...props} />
)

const Situation = ({ form, setForm }: SituationPropsType) => {
  const navigate = useNavigate()
  const submitInfo = () => {
    console.log('form.data', {
      ...form.data,
      last_diploma_title: form.data.last_diploma_title,
      last_diploma: form.data.last_diploma_name,
      last_diploma_prepared: form.data.last_diploma_prepared,
      former_salary: form.data.former_salary ?? null,
      former_weekly_work_duration: form.data.former_weekly_work_duration ?? null,
    })
    client
      .patch(`/apprenticeship/public/${form.data.global_id}/submit/`, {
        ...form.data,
        last_diploma_title: form.data.last_diploma_title,
        last_diploma: form.data.last_diploma_name,
        last_diploma_prepared: form.data.last_diploma_prepared,
        former_salary: form.data.former_salary || null,
        former_weekly_work_duration: form.data.former_weekly_work_duration || null,
      })
      .then((resp) => {
        if (resp.status === 200) {
          navigate({ pathname: './../confirmation/', search: location.search })
        }
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })
  }

  const handleChange = (key: string, value: string | boolean | Record<string, string> | number) => {
    setForm((prevState) => {
      const errors = prevState.errors
      delete errors[key]

      switch (key) {
        case 'last_diploma_title':
          if (!value) {
            errors[key] = 'Ce champ est obligatoire'
          }
          break
      }

      return {
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        errors: errors,
      }
    })
  }

  useEffect(() => {
    if (form.data.situation_before_contract === 'apprenticeship_contract') {
      handleChange('is_former_apprenticeship', true)
    } else if (form.data.situation_before_contract !== 'apprenticeship_contract') {
      handleChange('is_former_apprenticeship', false)
      handleChange('former_salary', '')
      handleChange('former_weekly_work_duration', '')
    }
  }, [form.data.situation_before_contract])

  return (
    <Layout form={form.data} steps={form.steps}>
      <Formtitle
        title="Votre situation"
        subtitle="Remplissez le formulaire ci-dessous pour poursuivre votre inscription"
      />
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bold">
            Sélectionnez le ou les statuts qui vous concernent
          </Typography>

          {form.data.apprenticeship_type === 'Apprentissage' && (
            <Grid container item alignItems="center">
              <Checkbox
                id="is_high_level_athlete"
                sx={{ ml: -1, mt: { xs: 0, sm: 0, md: 0 } }}
                checked={form.data.is_high_level_athlete ?? false}
                onClick={() => {
                  handleChange('is_high_level_athlete', !form.data.is_high_level_athlete)
                }}
              />
              <Typography>Sportif de haut niveau</Typography>
            </Grid>
          )}

          <Grid container item alignItems="center">
            <Checkbox
              id="is_disabled_worker"
              sx={{ ml: -1, mt: { xs: 0, sm: 0, md: 0 } }}
              checked={form.data.is_disabled_worker ?? false}
              onClick={() => {
                handleChange('is_disabled_worker', !form.data.is_disabled_worker)
              }}
            />
            <Typography>Travailleur handicapé</Typography>
          </Grid>

          {form.data.apprenticeship_type === 'Apprentissage' && (
            <Grid container item alignItems="center">
              <Checkbox
                id="want_to_create_enterprise"
                sx={{ ml: -1, mt: { xs: 0, sm: 0, md: 0 } }}
                checked={form.data.want_to_create_enterprise ?? false}
                onClick={() => {
                  handleChange('want_to_create_enterprise', !form.data.want_to_create_enterprise)
                }}
              />
              <Typography>Vous souhaitez créer une entreprise</Typography>
            </Grid>
          )}

          {form.data.apprenticeship_type === 'ContratPro' && (
            <Grid container item alignItems="center">
              <Checkbox
                id="is_registered_to_pole_emploi"
                sx={{ ml: -1, mt: { xs: 0, sm: 0, md: 0 } }}
                checked={form.data.is_registered_to_pole_emploi ?? false}
                onClick={() => {
                  handleChange('is_registered_to_pole_emploi', !form.data.is_registered_to_pole_emploi)
                }}
              />
              <Typography>Inscrit à Pôle Emploi</Typography>
            </Grid>
          )}
        </Grid>

        {form.data.apprenticeship_type === 'ContratPro' && form.data.is_registered_to_pole_emploi && (
          <>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Numéro d'inscription Pôle Emploi"
                variant="outlined"
                id="pole_emploi_registration_number"
                required
                value={form.data.pole_emploi_registration_number}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('pole_emploi_registration_number', e?.target.value)
                }}
                error={Boolean(form.errors.pole_emploi_registration_number)}
                helperText={form.errors.pole_emploi_registration_number}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Durée d'inscription (en mois)"
                variant="outlined"
                id="pole_emploi_duration"
                required
                value={form.data.pole_emploi_duration}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('pole_emploi_duration', e?.target.value)
                }}
                error={Boolean(form.errors.pole_emploi_duration)}
                helperText={form.errors.pole_emploi_duration}
              />
            </Grid>
          </>
        )}

        {form.data.apprenticeship_type === 'Apprentissage' && (
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="social_regime">Régime social</InputLabel>
              <Select
                labelId="social_regime-label"
                label="Régime social"
                onChange={(e) => {
                  handleChange('social_regime', e.target.value)
                }}
                value={form.data.social_regime}
              >
                <FixedMenuItem value="unknown">Je ne sais pas</FixedMenuItem>
                <FixedMenuItem value="msa">MSA</FixedMenuItem>
                <FixedMenuItem value="urssaf">URSSAF</FixedMenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel id="situation_before_contract">Situation avant le contrat</InputLabel>
            <Select
              labelId="situation_before_contract-label"
              label="Situation avant le contrat"
              onChange={(e) => {
                handleChange('situation_before_contract', e.target.value)
              }}
              value={form.data.situation_before_contract}
              MenuProps={{
                PaperProps: {
                  style: {
                    width: 0,
                  },
                },
              }}
            >
              {(form.data.apprenticeship_type === 'ContratPro'
                ? situation_before_contract_pro
                : situation_before_contract
              ).map((situation) => (
                <FixedMenuItem value={situation.id}>{situation.label}</FixedMenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {form.data.apprenticeship_type === 'ContratPro' && (
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel id="social_minimum_type">Type de minimum social</InputLabel>
              <Select
                labelId="social_minimum_type-label"
                label="Type de minimum social"
                onChange={(e) => {
                  handleChange('social_minimum_type', e.target.value)
                }}
                value={form.data.social_minimum_type}
              >
                <FixedMenuItem value="unknown">Je ne sais pas</FixedMenuItem>
                <FixedMenuItem value="rsa">Revenu de solidarité active</FixedMenuItem>
                <FixedMenuItem value="ass">Allocation de solidarité spécifique</FixedMenuItem>
                <FixedMenuItem value="aah">Allocation aux adultes handicapés</FixedMenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Checkbox
              id="want_to_move_out"
              sx={{ ml: -1, mt: { xs: 0, sm: 0, md: 0 } }}
              checked={form.data.is_former_apprenticeship ? true : false}
              disabled={form.data.situation_before_contract === 'apprenticeship_contract'}
              onClick={() => {
                handleChange('is_former_apprenticeship', !form.data.is_former_apprenticeship)
              }}
            />

            <Typography variant="body1">J'ai déjà effectué un apprentissage</Typography>
          </Grid>
        </Grid>
        {form.data.is_former_apprenticeship && (
          <>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  type="number"
                  required={form.data.is_former_apprenticeship}
                  label="Dernier salaire"
                  variant="outlined"
                  id="former_salary"
                  value={form.data.former_salary ?? undefined}
                  onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleChange('former_salary', e?.target.value)
                  }}
                  error={Boolean(form.errors.former_salary)}
                  helperText={form.errors.former_salary}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  label="Durée hebdomadaire de travail"
                  variant="outlined"
                  id="former_weekly_work_duration"
                  required={form.data.is_former_apprenticeship}
                  value={form.data.former_weekly_work_duration}
                  onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                    handleChange('former_weekly_work_duration', e?.target.value.replace(/[^0-9]/g, ''))
                  }}
                  error={Boolean(form.errors.former_weekly_work_duration)}
                  helperText={form.errors.former_weekly_work_duration}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <Typography variant="caption">
                Si vous avez déjà fait une alternance, merci de renseigner votre dernier salaire brut et votre durée
                hebdomadaire de travail
              </Typography>
            </Grid>
          </>
        )}

        {form.data.apprenticeship_type === 'Apprentissage' && (
          <>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Intitulé précis du dernier diplôme ou titre préparé"
                id="last_diploma_title"
                variant="outlined"
                required
                value={form.data.last_diploma_title}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('last_diploma_title', e?.target.value)
                }}
                error={Boolean(form.errors.last_diploma_title)}
                helperText={form.errors.last_diploma_title}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="last_student_year_outcome">Dernière année suivie en tant qu'étudiant</InputLabel>
                <Select
                  labelId="last_student_year_outcome-label"
                  label="Dernière année suivie en tant qu'étudiant"
                  onChange={(e) => {
                    handleChange('last_student_year_outcome', e.target.value)
                  }}
                  value={form.data.last_student_year_outcome}
                >
                  {form.last_student_year_outcomes.map((outcome) => (
                    <FixedMenuItem value={outcome.id}>{outcome.name}</FixedMenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="last_diploma_prepared-label">Dernier diplôme préparé</InputLabel>
                <Select
                  labelId="last_diploma_prepared-label"
                  label="Dernier diplôme préparé"
                  onChange={(e) => {
                    handleChange('last_diploma_prepared', e.target.value)
                  }}
                  value={form.data.last_diploma_prepared}
                >
                  {form.diplomas.map((diploma) => (
                    <FixedMenuItem value={diploma.id}>{diploma.name}</FixedMenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </>
        )}

        <Grid item xs={form.data.apprenticeship_type === 'Apprentissage' ? 6 : 12}>
          <FormControl fullWidth>
            <InputLabel id="last_diploma-label">Dernier diplôme obtenu</InputLabel>
            <Select
              labelId="last_diploma-label"
              label="Dernier diplôme obtenu"
              onChange={(e) => {
                if (e.target.value === 'none') {
                  handleChange('last_diploma_month', '')
                  handleChange('last_diploma_year', '')
                }

                handleChange('last_diploma_name', e.target.value)
              }}
              value={form.data.last_diploma_name}
            >
              {form.diplomas.map((diploma) => (
                <FixedMenuItem value={diploma.id}>{diploma.name}</FixedMenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} mt={1}>
          <Grid container item alignItems="center" flexWrap="nowrap">
            <Checkbox
              id="authorized_contact_with_companies"
              sx={{ ml: -1, mt: { xs: 0, sm: 0, md: 0 } }}
              checked={form.data.authorized_contact_with_companies ?? false}
              onClick={() => {
                handleChange('authorized_contact_with_companies', !form.data.authorized_contact_with_companies)
              }}
            />
            <Typography>
              En remplissant ce questionnaire, j’autorise Walter Learning à contacter des entreprises en mon nom dans le
              cadre de mon projet d’alternance.
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container item alignItems="center" flexWrap="nowrap">
            <Checkbox
              id="engaged_to_follow_training"
              sx={{ ml: -1, mt: { xs: 0, sm: 0, md: 0 } }}
              checked={form.data.engaged_to_follow_training ?? false}
              onClick={() => {
                handleChange('engaged_to_follow_training', !form.data.engaged_to_follow_training)
              }}
            />
            <Typography>
              Suite à la signature de mon contrat, je m’engage à suivre ma formation en alternance au sein de Walter
              Learning.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          mt: 2,
        }}
      >
        <Button
          variant="outlined"
          sx={{
            height: {
              xs: 45,
            },
          }}
          startIcon={<MdNavigateBefore />}
          onClick={() => {
            navigate({ pathname: './../profile/', search: location.search })
          }}
        >
          Précédent
        </Button>

        <Button
          variant="contained"
          sx={{
            height: {
              xs: 45,
            },
          }}
          disabled={!isApprenticeshipStepValid('situation', form)}
          endIcon={<MdNavigateNext />}
          onClick={submitInfo}
        >
          Valider
        </Button>
      </Grid>
    </Layout>
  )
}

export default Situation
