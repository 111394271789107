import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import bugsnag from '@bugsnag/js'
import BugsnagPluginReact, { BugsnagPluginReactResult } from '@bugsnag/plugin-react'
import { Error as ErrorView } from './pages'
import TagManager from 'react-gtm-module'

bugsnag.start({
  appType: 'front',
  apiKey: '5e623f6ca4c6e7dd0f140a342791d0cb',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production'],
})

TagManager.initialize({
  gtmId: 'GTM-MC4HJRQ',
})

const plugin = bugsnag.getPlugin('react') as BugsnagPluginReactResult
const ErrorBoundary = plugin.createErrorBoundary(React)

const onError = (event: any) => {
  // callback will only run for errors caught by boundary
  console.log('event', event)
  console.log('ERROR CAUGHT BY ERROR BOUNDARY')
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorView} onError={onError}>
      <App />
    </ErrorBoundary>
  </React.StrictMode>,
)
