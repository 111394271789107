import { ApprenticeshipFormType } from '../../utils/sharedTypes'
import { Formtitle, Layout } from '../../components'
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { MdNavigateNext } from 'react-icons/md'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { DateField } from '@mui/x-date-pickers'
import { isApprenticeshipStepValid } from '../../utils/misc'
import { emailValidator, phoneNumberValidator } from '../../utils/validators'
import { client } from '../../utils/client'
import Bugsnag from '@bugsnag/js'

type InformationPropsType = {
  form: ApprenticeshipFormType
  setForm: React.Dispatch<React.SetStateAction<ApprenticeshipFormType>>
}

const Information = ({ form, setForm }: InformationPropsType) => {
  const navigate = useNavigate()

  const submitInfo = () => {
    client
      .patch(`/apprenticeship/public/${form.data.global_id}/`, form.data)
      .then((resp) => {
        if (resp.status === 200) {
          if (form.data.has_apprenticeship_company) {
            navigate({ pathname: './../entreprise/', search: location.search })
          } else {
            navigate({ pathname: './../profile/', search: location.search })
          }
        }
      })
      .catch((err: Error) => {
        Bugsnag.notify(err)
        console.error(err)
      })
  }

  const handleChange = (key: string, value: string | boolean | Record<string, string>) => {
    setForm((prevState) => {
      const errors = prevState.errors
      delete errors[key]

      switch (key) {
        case 'first_name':
          if ((value as string).length === 0) {
            errors['first_name'] = 'Veuillez renseigner un prénom'
          }
          break

        case 'last_name':
          if ((value as string).length === 0) {
            errors['last_name'] = 'Veuillez renseigner un nom de famille'
          }
          break

        case 'nir':
          if ((value as string).length !== 15) {
            errors['nir'] = 'Veuillez renseigner un numéro de sécurité sociale valide'
          }
          break

        case 'mobile_phone':
          if (!phoneNumberValidator(value as string)) {
            errors['mobile_phone'] = 'Veuillez renseigner un numéro de téléphone valide'
          }
          break

        case 'email':
          if (!emailValidator(value as string)) {
            errors['email'] = 'Veuillez renseigner un e-mail valide'
          }
          break

        case 'gender':
          if (value === '') {
            errors['gender'] = 'Veuillez renseigner votre sexe'
          }
          break

        case 'nationality':
          if (value === '') {
            errors['nationality'] = 'Veuillez renseigner votre nationalité'
          }
          break

        case 'birth_date':
          if (value === '') {
            errors['birth_date'] = 'Veuillez renseigner votre date de naissance'
          }
          break

        case 'postal_code':
          if ((value as string).length === 0) {
            errors['postal_code'] = 'Veuillez renseigner un code postal'
          }
          break

        case 'city':
          if ((value as string).length === 0) {
            errors['city'] = 'Veuillez renseigner une ville'
          }
          break

        case 'legal_representative_country':
        case 'birth_country':
        case 'country':
          if ((value as string).length === 0) {
            errors[key] = 'Veuillez renseigner un pays'
          }
          break

        case 'street':
          if ((value as string).length === 0) {
            errors['street'] = 'Veuillez renseigner une adresse'
          }
          break

        case 'birth_department':
          if ((value as string).length === 0) {
            errors['birth_department'] = 'Veuillez renseigner un département'
          }

          if (!/^[0-9]{1,3}(A|B)?$/.test(value as string)) {
            errors['birth_department'] = 'Veuillez renseigner un département valide'
          }
          break

        case 'birth_city':
          if ((value as string).length === 0) {
            errors['birth_city'] = 'Veuillez renseigner une commune'
          }
          break

        case 'legal_representative_email':
          if (!emailValidator(value as string)) {
            errors['legal_representative_email'] = 'Veuillez renseigner un e-mail valide'
          }
          break

        case 'legal_representative_first_name':
          if ((value as string).length === 0) {
            errors['legal_representative_first_name'] = 'Veuillez renseigner un prénom'
          }
          break

        case 'legal_representative_last_name':
          if ((value as string).length === 0) {
            errors['legal_representative_last_name'] = 'Veuillez renseigner un nom de famille'
          }
          break

        case 'legal_representative_mobile_phone':
          if (!phoneNumberValidator(value as string)) {
            errors['legal_representative_mobile_phone'] = 'Veuillez renseigner un numéro de téléphone valide'
          }
          break

        case 'legal_representative_street':
          if ((value as string).length === 0) {
            errors['legal_representative_street'] = 'Veuillez renseigner une adresse'
          }
          break

        case 'legal_representative_postal_code':
          if ((value as string).length === 0) {
            errors['legal_representative_postal_code'] = 'Veuillez renseigner un code postal'
          }
          break

        case 'legal_representative_city':
          if ((value as string).length === 0) {
            errors['legal_representative_city'] = 'Veuillez renseigner une ville'
          }
          break

        case 'move_out_city':
          if ((value as string).length === 0) {
            errors['move_out_city'] = 'Veuillez renseigner une ville'
          }
          break

        case 'move_out_postal_code':
          if ((value as string).length === 0) {
            errors['move_out_postal_code'] = 'Veuillez renseigner un code postal'
          }
          break
      }

      return {
        ...prevState,
        data: {
          ...prevState.data,
          [key]: value,
        },
        errors: errors,
      }
    })
  }

  return (
    <Layout form={form.data} steps={form.steps}>
      <Formtitle
        title="Informations personnelles"
        subtitle="Remplissez le formulaire ci-dessous pour poursuivre votre inscription"
      />
      <Grid item container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Prénom"
            variant="outlined"
            id="first_name"
            required
            value={form.data.first_name}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('first_name', e?.target.value)
            }}
            error={Boolean(form.errors.first_name)}
            helperText={form.errors.first_name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Nom"
            id="last_name"
            variant="outlined"
            required
            value={form.data.last_name}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('last_name', e?.target.value)
            }}
            error={Boolean(form.errors.last_name)}
            helperText={form.errors.last_name}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="N° de sécurité sociale (15 chiffres)"
            id="nir"
            variant="outlined"
            required
            value={form.data.nir}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('nir', e?.target.value)
            }}
            error={Boolean(form.errors.nir)}
            helperText={form.errors.nir}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="N° de mobile"
            id="mobile_phone"
            variant="outlined"
            required
            value={form.data.mobile_phone}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('mobile_phone', e?.target.value)
            }}
            error={Boolean(form.errors.mobile_phone)}
            helperText={form.errors.mobile_phone}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Adresse e-mail"
            id="email"
            variant="outlined"
            required
            value={form.data.email}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('email', e?.target.value)
            }}
            error={Boolean(form.errors.email)}
            helperText={form.errors.email}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Adresse de mon logement pendant l’alternance"
            id="street"
            variant="outlined"
            required
            value={form.data.street}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('street', e?.target.value)
            }}
            error={Boolean(form.errors.street)}
            helperText={form.errors.street}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <TextField
            fullWidth
            label="Code postal"
            id="postal_code"
            variant="outlined"
            required
            value={form.data.postal_code}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('postal_code', e?.target.value)
            }}
            error={Boolean(form.errors.postal_code)}
            helperText={form.errors.postal_code}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <TextField
            fullWidth
            label="Ville"
            id="city"
            variant="outlined"
            required
            value={form.data.city}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('city', e?.target.value)
            }}
            error={Boolean(form.errors.city)}
            helperText={form.errors.city}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <TextField
            fullWidth
            label="Pays"
            id="country"
            variant="outlined"
            required
            value={form.data.country}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('country', e?.target.value)
            }}
            error={Boolean(form.errors.country)}
            helperText={form.errors.country}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container alignItems="center">
            <Checkbox
              id="want_to_move_out"
              sx={{ ml: -1, mt: { xs: 0, sm: 0, md: 0 } }}
              checked={form.data.want_to_move_out ?? false}
              onClick={() => {
                handleChange('want_to_move_out', !form.data.want_to_move_out)
              }}
            />

            <Typography variant="body1" fontWeight="">
              Je prévois de déménager
            </Typography>
          </Grid>
        </Grid>

        {form.data.want_to_move_out && (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Ville de déménagement"
                id="move_out_city"
                variant="outlined"
                required
                value={form.data.move_out_city}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('move_out_city', e?.target.value)
                }}
                error={Boolean(form.errors.move_out_city)}
                helperText={form.errors.move_out_city}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Code postal de déménagement"
                id="move_out_postal_code"
                variant="outlined"
                required
                value={form.data.move_out_postal_code}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('move_out_postal_code', e?.target.value)
                }}
                error={Boolean(form.errors.move_out_postal_code)}
                helperText={form.errors.move_out_postal_code}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={6} lg={4}>
          <FormControl fullWidth required>
            <InputLabel id="sex-label">Sexe</InputLabel>
            <Select
              fullWidth
              labelId="sex-label"
              label="Sexe"
              onChange={(e) => {
                handleChange('gender', e.target.value)
              }}
              value={form.data.gender}
              error={Boolean(form.errors.gender)}
            >
              <MenuItem value="Homme">Homme</MenuItem>
              <MenuItem value="Femme">Femme</MenuItem>
            </Select>
            <FormHelperText>{form.errors.gender}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <FormControl fullWidth required>
            <InputLabel id="nationality-label">Nationalité</InputLabel>
            <Select
              labelId="nationality-label"
              label="Nationalité"
              onChange={(e) => {
                handleChange('nationality', e.target.value)
              }}
              value={form.data.nationality}
            >
              <MenuItem value="Française">Française</MenuItem>
              <MenuItem value="Union Européenne">Union européenne</MenuItem>
              <MenuItem value="Extra Européen">Hors Union européenne</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6} lg={4}>
          <DateField
            fullWidth
            id="birth_date"
            label="Date de naissance"
            format="DD/MM/YYYY"
            disableFuture
            value={form.data.birth_date ? dayjs(form.data.birth_date) : null}
            onChange={(value) => {
              //HACKY
              if (value && dayjs(value).isValid() && `${dayjs(value).year()}`.length === 4) {
                handleChange('birth_date', dayjs(value).format('YYYY-MM-DD'))
              }
            }}
            slots={{
              textField: (params) => (
                <TextField
                  id="birthdate_textField"
                  {...params}
                  fullWidth
                  onBlur={() => {
                    if (!dayjs(form.data.birth_date).isValid()) {
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          errors: {
                            ...prevState.errors,
                            birth_date: 'Veuillez entrer une date de naissance valide',
                          },
                        }
                      })
                    }
                  }}
                  error={Boolean(form.errors.birth_date)}
                  helperText={form.errors.birth_date}
                />
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid item container>
            <Typography variant="body1" fontWeight="bold">
              Je suis né à l'étranger
            </Typography>

            <Grid container alignItems="center">
              <Checkbox
                id="is_born_abroad"
                sx={{ ml: -1, mt: { xs: 0, sm: 0, md: 0 } }}
                checked={form.data.is_born_abroad === true ?? false}
                onClick={() => {
                  handleChange('is_born_abroad', true)
                }}
              />

              <Typography>Oui</Typography>
            </Grid>
            <Grid container alignItems="center">
              <Checkbox
                id="is_born_abroad"
                sx={{ ml: -1, mt: { xs: 0, sm: 0, md: 0 } }}
                checked={form.data.is_born_abroad === false ?? false}
                onClick={() => {
                  handleChange('is_born_abroad', false)
                  handleChange('birth_department', '')
                  handleChange('birth_city', '')
                  handleChange('birth_country', '')
                }}
              />

              <Typography>Non</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Commune"
            id="birth_city"
            variant="outlined"
            required
            value={form.data.birth_city}
            onChange={(e: React.FocusEvent<HTMLInputElement>) => {
              handleChange('birth_city', e?.target.value)
            }}
            error={Boolean(form.errors.birth_city)}
            helperText={form.errors.birth_city}
          />
        </Grid>

        {form.data.is_born_abroad === false ? (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="N° Département"
              id="department"
              variant="outlined"
              required
              value={form.data.birth_department}
              onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                handleChange('birth_department', e?.target.value)
              }}
              error={Boolean(form.errors.birth_department)}
              helperText={form.errors.birth_department}
            />
          </Grid>
        ) : (
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Pays de naissance"
              id="birth_country"
              variant="outlined"
              value={form.data.birth_country}
              onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                handleChange('birth_country', e?.target.value)
              }}
              error={Boolean(form.errors.birth_country)}
              helperText={form.errors.birth_country}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Grid item container>
            <Typography variant="body1" fontWeight="bold">
              J'ai un représentant légal
            </Typography>

            <Grid container alignItems="center">
              <Checkbox
                id="has_legal_representative"
                sx={{ ml: -1, mt: { xs: 0, sm: 0, md: 0 } }}
                checked={form.data.has_legal_representative === true ?? false}
                onClick={() => {
                  handleChange('has_legal_representative', true)
                }}
              />

              <Typography>Oui</Typography>
            </Grid>
            <Grid container alignItems="center">
              <Checkbox
                id="has_legal_representative"
                sx={{ ml: -1, mt: { xs: 0, sm: 0, md: 0 } }}
                checked={form.data.has_legal_representative === false ?? false}
                onClick={() => {
                  handleChange('has_legal_representative', false)
                  handleChange('legal_representative_email', '')
                  handleChange('legal_representative_first_name', '')
                  handleChange('legal_representative_last_name', '')
                }}
              />

              <Typography>Non</Typography>
            </Grid>
          </Grid>
        </Grid>

        {form.data.has_legal_representative === true && (
          <>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Prénom du représentant légal"
                id="legal_representative_first_name"
                variant="outlined"
                required
                value={form.data.legal_representative_first_name}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('legal_representative_first_name', e?.target.value)
                }}
                error={Boolean(form.errors.legal_representative_first_name)}
                helperText={form.errors.legal_representative_first_name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Nom du représentant légal"
                id="legal_representativ_last_name"
                variant="outlined"
                required
                value={form.data.legal_representative_last_name}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('legal_representative_last_name', e?.target.value)
                }}
                error={Boolean(form.errors.legal_representative_last_name)}
                helperText={form.errors.legal_representative_last_name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Adresse e-mail du représentant légal"
                id="legal_representative_email"
                variant="outlined"
                required
                value={form.data.legal_representative_email}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('legal_representative_email', e?.target.value)
                }}
                error={Boolean(form.errors.legal_representative_email)}
                helperText={form.errors.legal_representative_email}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="N° de mobile du représentant légal"
                id="legal_representative_mobile_phone"
                variant="outlined"
                required
                value={form.data.legal_representative_mobile_phone}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('legal_representative_mobile_phone', e?.target.value)
                }}
                error={Boolean(form.errors.legal_representative_mobile_phone)}
                helperText={form.errors.legal_representative_mobile_phone}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Adresse du représentant légal"
                id="legal_representative_street"
                variant="outlined"
                required
                value={form.data.legal_representative_street}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('legal_representative_street', e?.target.value)
                }}
                error={Boolean(form.errors.legal_representative_street)}
                helperText={form.errors.legal_representative_street}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                label="Code postal du représentant légal"
                id="legal_representative_postal_code"
                variant="outlined"
                required
                value={form.data.legal_representative_postal_code}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('legal_representative_postal_code', e?.target.value)
                }}
                error={Boolean(form.errors.legal_representative_postal_code)}
                helperText={form.errors.legal_representative_postal_code}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                label="Ville du représentant légal"
                id="legal_representative_city"
                variant="outlined"
                required
                value={form.data.legal_representative_city}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('legal_representative_city', e?.target.value)
                }}
                error={Boolean(form.errors.legal_representative_city)}
                helperText={form.errors.legal_representative_city}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                label="Pays du représentant légal"
                id="legal_representative_country"
                variant="outlined"
                required
                value={form.data.legal_representative_country}
                onChange={(e: React.FocusEvent<HTMLInputElement>) => {
                  handleChange('legal_representative_country', e?.target.value)
                }}
                error={Boolean(form.errors.legal_representative_country)}
                helperText={form.errors.legal_representative_country}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        item
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{
            height: {
              xs: 45,
            },
          }}
          disabled={!isApprenticeshipStepValid('information', form)}
          endIcon={<MdNavigateNext />}
          onClick={submitInfo}
        >
          Suivant
        </Button>
      </Grid>
    </Layout>
  )
}

export default Information
